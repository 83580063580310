<template>
    <div class="auth-template">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 pt-5 mt-5">
                <router-view />
            </div>
            <div class="col-12 col-sm-4 col-md-6 bg-img" />
        </div>
    </div>
</template>
<style>
    .auth-template{
        background-image: url('/images/auth.png');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
    }
    .auth-template{
        max-width: 100%;
        overflow-x: hidden;
    }
</style>